import {
  PageContainer,
  ProTable,
  ProFormText,
  ModalForm,
} from "@ant-design/pro-components";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { AddBank, GetAllBank, UpdateBank } from "../../actions/bank";
import { Button, Form } from "antd";
import moment from "moment";
import UpdateForm from "./components/UpdateForm";
import { PlusOutlined } from "@ant-design/icons";
const Bank = () => {
  const dispatch = useAppDispatch();
  const bank_selector = useAppSelector((state) => state.bank);
  const actionRef = useRef<ActionType>();
  const [createForm] = Form.useForm();
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);

  const [currentRow, setCurrentRow] = useState<API.BankListItem>();

  useEffect(() => {
    dispatch(GetAllBank({}));
  }, [dispatch]);

  const handleSearch = (params: any) => {
    dispatch(GetAllBank(params));
  };

  const handleReset = () => {
    dispatch(GetAllBank({}));
  };

  const handleAddBank = async (values: any) => {
    const status = await dispatch(AddBank(values));
    if (status) {
      createForm.resetFields();
      handleModalVisible(false);
      dispatch(GetAllBank({}));
    }
  };
  const handleUpdateBank = async (data: API.BankListItem) => {
    const status = await dispatch(UpdateBank(data));
    return status;
  };
  const columns: ProColumns<API.BankListItem>[] = [
    {
      title: "ID", // Column header
      dataIndex: "id", // Key in the dataSource object
      key: "id", // Unique key for this column
      hideInSearch: true,
    },
    {
      title: "银行名字",
      dataIndex: "name",
    },
    {
      title: "输入日期",
      dataIndex: "createdAt",
      valueType: "date",
      render: (dom, entity) => {
        const createdDate = moment(entity.createdAt).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        return createdDate;
      },
    },

    {
      title: "状态",
      dataIndex: "isDelete",
      valueEnum: {
        false: {
          text: "活跃",
          status: "Success",
        },
        true: {
          text: "注销",
          status: "Error",
        },
      },
    },
    {
      title: "选项",
      dataIndex: "option",
      valueType: "option",
      hideInDescriptions: true,
      render: (_, record) => [
        <Button
          color="primary"
          variant="link"
          onClick={() => {
            handleUpdateModalVisible(true);
            setCurrentRow(record);
          }}
        >
          更改
        </Button>,
      ],
    },
  ];

  return (
    <>
      <PageContainer>
        <div>
          <ProTable<API.BankListItem, API.PageParams>
            actionRef={actionRef}
            headerTitle="银行列表"
            columns={columns}
            rowKey="id"
            pagination={{
              pageSize: 10,
              // current: bank_selector?.bankList?.pageNumber,
            }}
            dataSource={bank_selector?.bankList?.data}
            loading={bank_selector?.isLoadingGetBankList}
            onSubmit={handleSearch}
            onReset={handleReset}
            toolBarRender={() => [
              <Button
                type="primary"
                key="primary"
                onClick={() => {
                  handleModalVisible(true);
                }}
              >
                <PlusOutlined />
                添加银行
              </Button>,
            ]}
          />
          <ModalForm
            modalProps={{
              maskClosable: false,
            }}
            loading={bank_selector?.isLoadingAddBank}
            title="添加银行"
            visible={createModalVisible}
            onVisibleChange={handleModalVisible}
            width="400px"
            form={createForm}
            onFinish={async (value) => {
              await handleAddBank(value);
            }}
          >
            <>
              <ProFormText
                rules={[
                  {
                    required: true,
                    message: "请填入银行名字",
                  },
                ]}
                width="md"
                name="bankName"
                label="银行名字"
              />
            </>
          </ModalForm>
          <UpdateForm
            onSubmit={async (value) => {
              const success = await handleUpdateBank(value);
              if (success) {
                handleUpdateModalVisible(false);
                setCurrentRow(undefined);
                dispatch(GetAllBank({}));
              }
            }}
            updateBankLoading={bank_selector?.isLoadingUpdateBank}
            onCancel={() => {
              handleUpdateModalVisible(false);
              setCurrentRow(undefined);
            }}
            onVisibleChange={handleUpdateModalVisible}
            updateModalVisible={updateModalVisible}
            values={currentRow || {}}
          />
        </div>
      </PageContainer>
    </>
  );
};

export default Bank;
