import {
  ADD_TRANSACTION_FAILURE,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  GET_ALL_TRANSACTION_FAILURE,
  GET_ALL_TRANSACTION_REQUEST,
  GET_ALL_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAILURE,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_SUCCESS,
} from "../actions/actions";

const INITIAL_STATE = {
  transactionList: null,
  isLoadingGetAllTransaction: false,
  isLoadingAddTransaction: false,
  isLoadingUpdateTransaction: false,
};

const TransactionReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_ALL_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoadingGetAllTransaction: action.payload.isLoadingGetAllTransaction,
      };

    case GET_ALL_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoadingGetAllTransaction: action.payload.isLoadingGetAllTransaction,
        transactionList: action.payload.transactionList,
      };
    case GET_ALL_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoadingGetAllTransaction: action.payload.isLoadingGetAllTransaction,
      };
    case UPDATE_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoadingUpdateTransaction: action.payload.isLoadingUpdateTransaction,
      };
    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoadingUpdateTransaction: action.payload.isLoadingUpdateTransaction,
      };
    case UPDATE_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoadingUpdateTransaction: action.payload.isLoadingUpdateTransaction,
      };
    case ADD_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoadingAddTransaction: action.payload.isLoadingAddTransaction,
      };
    case ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoadingAddTransaction: action.payload.isLoadingAddTransaction,
      };
    case ADD_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoadingAddTransaction: action.payload.isLoadingAddTransaction,
      };
    default:
      return state;
  }
};

export default TransactionReducer;
