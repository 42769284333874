import {
  ADD_BANK_FAILURE,
  ADD_BANK_REQUEST,
  ADD_BANK_SUCCESS,
  GET_BANK_LIST_FAILURE,
  GET_BANK_LIST_REQUEST,
  GET_BANK_LIST_SUCCESS,
  UPDATE_BANK_FAILURE,
  UPDATE_BANK_REQUEST,
  UPDATE_BANK_SUCCESS,
} from "./actions";
import axios from "../AxiosConfig";
import { ShowSnackbar } from "./snackbar";

const BANK_ENDPOINT = "/api/Bank/";

export const GetAllBank = (params: any) => {
  return (dispatch: any) => {
    if (localStorage.getItem("currentUser")) {
      dispatch({
        type: GET_BANK_LIST_REQUEST,
        payload: { isLoadingGetBankList: true },
      });

      axios
        .get(`${BANK_ENDPOINT}GetAllBanks`, { params })
        .then((res) => {
          dispatch({
            type: GET_BANK_LIST_SUCCESS,
            payload: { isLoadingGetBankList: false, bankList: res?.data },
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_BANK_LIST_FAILURE,
            payload: { isLoadingGetBankList: false },
          });
        });
    }
  };
};

export const UpdateBank = (data: API.BankListItem) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: UPDATE_BANK_REQUEST,
        payload: { isLoadingUpdateBank: true },
      });

      axios
        .post(`${BANK_ENDPOINT}Update`, data)
        .then((res) => {
          dispatch({
            type: UPDATE_BANK_SUCCESS,
            payload: { isLoadingUpdateBank: false },
          });
          dispatch(ShowSnackbar("更新成功", "success"));
          resolve(true); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_BANK_FAILURE,
            payload: { isLoadingUpdateBank: false },
          });
          dispatch(ShowSnackbar("更新失败", "error"));
          resolve(false); // Return false on failure
        });
    });
  };
};

export const AddBank = (data: any) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: ADD_BANK_REQUEST,
        payload: { isLoadingAddBank: true },
      });

      axios
        .post(`${BANK_ENDPOINT}Create`, data)
        .then((res) => {
          dispatch({
            type: ADD_BANK_SUCCESS,
            payload: { isLoadingAddBank: false },
          });
          dispatch(ShowSnackbar("添加成功", "success"));
          resolve(true); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: ADD_BANK_FAILURE,
            payload: { isLoadingAddBank: false },
          });
          dispatch(ShowSnackbar("添加失败", "error"));
          resolve(false); // Return false on failure
        });
    });
  };
};
