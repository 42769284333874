import {
  ADD_BANK_FAILURE,
  ADD_BANK_REQUEST,
  ADD_BANK_SUCCESS,
  GET_BANK_LIST_FAILURE,
  GET_BANK_LIST_REQUEST,
  GET_BANK_LIST_SUCCESS,
  UPDATE_BANK_FAILURE,
  UPDATE_BANK_REQUEST,
  UPDATE_BANK_SUCCESS,
} from "../actions/actions";

const INITIAL_STATE = {
  isLoadingGetBankList: false,
  bankList: null,
  isLoadingUpdateBank: false,
  isLoadingAddBank: false,
};

const BankReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_BANK_LIST_REQUEST:
      return {
        ...state,
        isLoadingGetBankList: action.payload.isLoadingGetBankList,
      };

    case GET_BANK_LIST_SUCCESS:
      return {
        ...state,
        isLoadingGetBankList: action.payload.isLoadingGetBankList,
        bankList: action.payload.bankList,
      };
    case GET_BANK_LIST_FAILURE:
      return {
        ...state,
        isLoadingGetBankList: action.payload.isLoadingGetBankList,
      };
    case UPDATE_BANK_REQUEST:
      return {
        ...state,
        isLoadingUpdateBank: action.payload.isLoadingUpdateBank,
      };
    case UPDATE_BANK_SUCCESS:
      return {
        ...state,
        isLoadingUpdateBank: action.payload.isLoadingUpdateBank,
      };
    case UPDATE_BANK_FAILURE:
      return {
        ...state,
        isLoadingUpdateBank: action.payload.isLoadingUpdateBank,
      };
    case ADD_BANK_REQUEST:
      return {
        ...state,
        isLoadingAddBank: action.payload.isLoadingAddBank,
      };
    case ADD_BANK_SUCCESS:
      return {
        ...state,
        isLoadingAddBank: action.payload.isLoadingAddBank,
      };
    case ADD_BANK_FAILURE:
      return {
        ...state,
        isLoadingAddBank: action.payload.isLoadingAddBank,
      };
    default:
      return state;
  }
};

export default BankReducer;
