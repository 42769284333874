import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Menu, Spin } from "antd";
import React, { useCallback } from "react";
import { useAppSelector } from "../../redux/hooks";
import { LOGIN_ROUTE } from "../../constant";
import HeaderDropdown from "../HeaderDropdown";
import { useNavigate } from "react-router-dom";
export type GlobalHeaderRightProps = {
  menu?: boolean;
};

/**
 * 退出登录，并且将当前的 url 保存
 */

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu }) => {
  const userSelector = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const onMenuClick = (event: any) => {
    const { key } = event;
    if (key === "logout") {
      localStorage.clear();
      navigate(LOGIN_ROUTE, { replace: true });
      return;
    }
  };

  const menuItems = [
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: "退出登录",
    },
  ];

  const menuHeaderDropdown = (
    <Menu selectedKeys={[]} onClick={onMenuClick} items={menuItems} />
  );

  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <div className="header-dropdown">
        <span style={{ color: "white" }}>{userSelector?.userInfo?.name}</span>
      </div>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
