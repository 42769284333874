import React, { useEffect } from "react";
import {
  ModalForm,
  ProFormText,
  ProForm,
  ProFormSwitch,
  ProFormDigit,
} from "@ant-design/pro-components";
import { Form, Input } from "antd";

export type FormValueType = Partial<API.AccountListItem>;

export type UpdateFormProps = {
  onCancel: any;
  //   onCancel: (flag?: boolean, formVals?: FormValueType) => void;
  onSubmit: (values: FormValueType) => Promise<void>;
  updateModalVisible: boolean;
  onVisibleChange: any;
  values: Partial<API.AccountListItem>;
  updateAccountLoading: boolean;
};

const UpdateForm: React.FC<UpdateFormProps> = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props.values);
  }, [props.values, form]);

  return (
    <ModalForm<API.AccountListItem>
      width={700}
      title="更新账户"
      visible={props.updateModalVisible}
      onVisibleChange={props.onVisibleChange}
      modalProps={{
        onCancel: props.onCancel,
      }}
      onFinish={props.onSubmit}
      form={form}
      initialValues={{ ...props.values }}
      loading={props.updateAccountLoading}
    >
      <ProForm.Group>
        <ProFormText name="id" label="系统ID" readonly />
        <ProFormText name="userName" label="登入用户名" readonly />
        <ProFormText name="userId" label="系统用户ID" readonly />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText
          rules={[
            {
              required: true,
              message: "请填入组别",
            },
          ]}
          width="md"
          name="category"
          label="组别"
        />
        <ProFormText
          rules={[
            {
              required: true,
              message: "请填入户口类型",
            },
          ]}
          width="md"
          name="type"
          label="户口类型"
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText
          rules={[
            {
              required: true,
              message: "请填入户口号码",
            },
          ]}
          width="md"
          name="accountNumber"
          label="户口号码"
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormDigit
          rules={[
            {
              required: true,
              message: "请输入当前户口数额",
            },
          ]}
          width="sm"
          name="availableAmount"
          label="当前户口数额 (Available Amount)"
        />
        <ProFormDigit
          rules={[
            {
              required: true,
              message: "请输入当前可转账数额",
            },
          ]}
          width="sm"
          name="withdrawableAmount"
          label="当前可转账数额 (Withdrawable Amount)"
        />
        <ProFormDigit
          rules={[
            {
              required: true,
              message: "请输入剩余转账数额",
            },
          ]}
          width="sm"
          name="transferLimit"
          label="剩余转账数额 (Transfer Limit Left)"
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSwitch
          name="isDelete"
          label="注销账户"
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </ProForm.Group>
    </ModalForm>
  );
};

export default UpdateForm;
