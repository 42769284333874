import {
  PageContainer,
  ProTable,
  ProFormText,
  ModalForm,
  ProFormDigit,
  ProFormSelect,
  ProFormTextArea,
} from "@ant-design/pro-components";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Button, Card, Form } from "antd";
import moment from "moment";
import UpdateForm from "./components/UpdateForm";
import { PlusOutlined } from "@ant-design/icons";
import { GetAllUsers } from "../../actions/user";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  AddTransaction,
  GetAllTransaction,
  UpdateTransaction,
} from "../../actions/transaction";
import { GetAccountById } from "../../actions/account";
const Transaction = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const transaction_selector = useAppSelector((state) => state.transaction);
  const account_selector = useAppSelector((state) => state.account);
  const actionRef = useRef<ActionType>();
  const [createForm] = Form.useForm();
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);

  const [currentRow, setCurrentRow] = useState<API.TransactionListItem>();

  useEffect(() => {
    dispatch(GetAllTransaction({ accountId: params?.id }));
    dispatch(GetAccountById({ accountId: params?.id }));
  }, [dispatch, params?.id]);

  const handleAddTransaction = async (values: any) => {
    const status = await dispatch(AddTransaction(values));
    if (status) {
      createForm.resetFields();
      handleModalVisible(false);
      dispatch(GetAllTransaction({ accountId: params?.id }));
    }
  };
  const handleUpdateTransaction = async (data: API.TransactionListItem) => {
    const status = await dispatch(UpdateTransaction(data));
    return status;
  };
  const columns: ProColumns<API.TransactionListItem>[] = [
    {
      title: "ID", // Column header
      dataIndex: "id", // Key in the dataSource object
      key: "id", // Unique key for this column
      hideInSearch: true,
    },
    {
      title: "In/Out",
      dataIndex: "type",
      render: (dom, entity) => {
        return entity.type === 1 ? (
          <p style={{ color: "green", fontWeight: "700" }}>In</p>
        ) : (
          <p style={{ color: "red", fontWeight: "700" }}>Out</p>
        );
      },
    },
    {
      title: "数额",
      dataIndex: "amount",
    },
    {
      title: "流水简介",
      dataIndex: "description",
    },
    {
      title: "显示日期",
      dataIndex: "dateToDisplay",
      valueType: "date",
      render: (dom, entity) => {
        const createdDate = moment(entity.dateToDisplay).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        return createdDate;
      },
    },
    {
      title: "输入日期",
      dataIndex: "createdAt",
      valueType: "date",
      render: (dom, entity) => {
        const createdDate = moment(entity.createdAt).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        return createdDate;
      },
    },

    {
      title: "状态",
      dataIndex: "isDelete",
      valueEnum: {
        false: {
          text: "活跃",
          status: "Success",
        },
        true: {
          text: "注销",
          status: "Error",
        },
      },
    },
    {
      title: "选项",
      dataIndex: "option",
      valueType: "option",
      hideInDescriptions: true,
      render: (_, record) => [
        <Button
          color="primary"
          variant="link"
          onClick={() => {
            handleUpdateModalVisible(true);
            setCurrentRow(record);
          }}
        >
          更改
        </Button>,
      ],
    },
  ];

  return (
    <>
      <PageContainer>
        <Card>
          <p style={{ fontSize: "24px" }}>
            {account_selector?.accountData?.data?.type}
          </p>
          <p style={{ fontSize: "18px" }}>
            {account_selector?.accountData?.data?.accountNumber}
          </p>
        </Card>
        <div style={{ marginTop: "15px" }}>
          <ProTable<API.TransactionListItem, API.PageParams>
            actionRef={actionRef}
            headerTitle="账号列表"
            columns={columns}
            rowKey="id"
            pagination={{
              pageSize: 10,
              // current: bank_selector?.bankList?.pageNumber,
            }}
            dataSource={transaction_selector?.transactionList?.data}
            loading={transaction_selector?.isLoadingGetAllTransaction}
            search={false}
            toolBarRender={() => [
              <Button
                type="primary"
                key="primary"
                onClick={() => {
                  handleModalVisible(true);
                }}
              >
                <PlusOutlined />
                添加流水记录
              </Button>,
            ]}
          />
          <ModalForm
            modalProps={{
              maskClosable: false,
            }}
            loading={transaction_selector?.isLoadingAddTransaction}
            title="添加流水"
            visible={createModalVisible}
            onVisibleChange={handleModalVisible}
            width="400px"
            form={createForm}
            onFinish={async (value) => {
              await handleAddTransaction(value);
            }}
          >
            <>
              <ProFormText
                name="userId"
                hidden
                initialValue={account_selector?.accountData?.data?.userId}
              />
              <ProFormText
                name="accountId"
                hidden
                initialValue={account_selector?.accountData?.data?.id}
              />
              <ProFormSelect
                rules={[
                  {
                    required: true,
                    message: "请选择In/Out",
                  },
                ]}
                width="md"
                name="type"
                label="In/Out"
                options={[
                  { label: "In", value: 1 },
                  { label: "Out", value: 2 },
                ]}
              />
              <ProFormTextArea
                name="description"
                label="流水简介"
                rules={[
                  {
                    required: true,
                    message: "请输入流水简介",
                  },
                ]}
              />
              <ProFormDigit
                rules={[
                  {
                    required: true,
                    message: "请输入流水数额",
                  },
                ]}
                width="md"
                name="amount"
                label="数额 "
              />
            </>
          </ModalForm>
          <UpdateForm
            onSubmit={async (value) => {
              const success = await handleUpdateTransaction(value);
              if (success) {
                handleUpdateModalVisible(false);
                setCurrentRow(undefined);
                dispatch(GetAllTransaction({ accountId: params?.id }));
              }
            }}
            updateTransactionLoading={
              transaction_selector?.isLoadingUpdateTransaction
            }
            onCancel={() => {
              handleUpdateModalVisible(false);
              setCurrentRow(undefined);
            }}
            onVisibleChange={handleUpdateModalVisible}
            updateModalVisible={updateModalVisible}
            values={currentRow || {}}
          />
        </div>
      </PageContainer>
    </>
  );
};

export default Transaction;
