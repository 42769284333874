import {
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  POST_LOGIN_REQUEST,
  POST_LOGIN_FAILURE,
  POST_LOGIN_SUCCESS,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "./actions";
import axios from "../AxiosConfig";
import { DASHBOARD_ROUTE, USER_ROUTE } from "../constant";
import { ShowSnackbar } from "./snackbar";

const USER_ENDPOINT = "/api/User/";
export const GetUserInfo = () => {
  return (dispatch: any) => {
    if (localStorage.getItem("currentUser")) {
      const curentLoginUser = JSON.parse(
        localStorage.getItem("currentUser") || ""
      );
      dispatch({
        type: GET_USER_INFO_REQUEST,
        payload: { isLoadingGetUserInfo: true },
      });

      axios
        .get(`${USER_ENDPOINT}${curentLoginUser?.userId}`)
        .then((res) => {
          dispatch({
            type: GET_USER_INFO_SUCCESS,
            payload: { isLoadingGetUserInfo: false, userInfo: res?.data?.data },
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_USER_INFO_FAILURE,
            payload: { isLoadingGetUserInfo: false },
          });
        });
    }
  };
};

export const Login = (loginData: any, navigate: any) => {
  return (dispatch: any) => {
    dispatch({
      type: POST_LOGIN_REQUEST,
      payload: { isLoadingLogin: true },
    });
    axios
      .post(`${USER_ENDPOINT}Login`, loginData)
      .then((res) => {
        const responseData = res?.data?.data;
        const sessionData = {
          userId: responseData?.userId,
          accessToken: responseData?.accessToken,
        };
        localStorage.setItem("currentUser", JSON.stringify(sessionData));
        dispatch({
          type: POST_LOGIN_SUCCESS,
          payload: { isLoadingLogin: false },
        });
        dispatch(ShowSnackbar("登入成功", "success"));
        navigate(USER_ROUTE);
      })
      .catch((err) => {
        dispatch(ShowSnackbar("登入失败", "error"));
        dispatch({
          type: POST_LOGIN_FAILURE,
          payload: { isLoadingLogin: false },
        });
      });
  };
};

export const GetAllUsers = (params: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ALL_USERS_REQUEST,
      payload: { isLoadingGetAllUsers: true },
    });

    axios
      .get(`${USER_ENDPOINT}GetAllUsers`, { params })
      .then((res) => {
        dispatch({
          type: GET_ALL_USERS_SUCCESS,
          payload: { isLoadingGetAllUsers: false, userList: res?.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_USERS_FAILURE,
          payload: { isLoadingGetAllUsers: false },
        });
      });
  };
};

export const AddUser = (data: any) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: ADD_USER_REQUEST,
        payload: { isLoadingAddUser: true },
      });

      axios
        .post(`${USER_ENDPOINT}Create`, data)
        .then((res) => {
          dispatch({
            type: ADD_USER_SUCCESS,
            payload: { isLoadingAddUser: false },
          });
          dispatch(ShowSnackbar("添加成功", "success"));
          resolve(true); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: ADD_USER_FAILURE,
            payload: { isLoadingAddUser: false },
          });
          dispatch(ShowSnackbar("添加失败", "error"));
          resolve(false); // Return false on failure
        });
    });
  };
};

export const UpdateUser = (data: API.UserListItem) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: UPDATE_USER_REQUEST,
        payload: { isLoadingUpdateUser: true },
      });

      axios
        .post(`${USER_ENDPOINT}Update`, data)
        .then((res) => {
          dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: { isLoadingUpdateUser: false },
          });
          dispatch(ShowSnackbar("更新成功", "success"));
          resolve(true); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_USER_FAILURE,
            payload: { isLoadingUpdateUser: false },
          });
          dispatch(ShowSnackbar("更新失败", "error"));
          resolve(false); // Return false on failure
        });
    });
  };
};
