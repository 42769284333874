import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { LoginForm, ProFormText } from "@ant-design/pro-components";
import { Tabs } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Login } from "../../actions/user";
import styles from "./login.module.scss";
const LoginPage = () => {
  const [type, setType] = useState<string>("account");
  const dispatch = useAppDispatch();
  const user_selector = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const tabItems = [
    {
      label: "登录",
      key: "account",
    },
  ];

  const handleSubmit = async (data: any) => {
    dispatch(Login(data, navigate));
  };

  return (
    <>
      <div className={styles.login_div}>
        <LoginForm
          loading={user_selector?.isLoadingLogin}
          initialValues={{
            autoLogin: false,
          }}
          onFinish={async (values) => {
            await handleSubmit(values);
          }}
        >
          <Tabs activeKey={type} onChange={setType} items={tabItems} />
          {type === "account" && (
            <>
              <ProFormText
                name="email"
                fieldProps={{
                  size: "large",
                  prefix: <UserOutlined />,
                }}
                placeholder="邮件"
                rules={[
                  {
                    required: true,
                    message: "请输入邮件!",
                  },
                  {
                    type: "email",
                    message: "请输入正确邮件!",
                  },
                ]}
              />
              <ProFormText.Password
                name="password"
                fieldProps={{
                  size: "large",
                  prefix: <LockOutlined />,
                }}
                placeholder="密码"
                rules={[
                  {
                    required: true,
                    message: "请输入密码！",
                  },
                ]}
              />
            </>
          )}
        </LoginForm>
      </div>
    </>
  );
};

export default LoginPage;
