import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "./constant";

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage?.getItem("currentUser") ? true : false; //add a dynamic method call
  // Replace with your logic to check if the user is an admin
  //   const userRole = isAdmin; // Example: This should be fetched from your auth context or state

  //   return userRole ? element : <Navigate to="/unauthorized" />;
  useEffect(() => {
    if (!isAuthenticated) {
      return navigate(LOGIN_ROUTE, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
