import axios from "../AxiosConfig";
import {
  ADD_TRANSACTION_FAILURE,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  GET_ALL_TRANSACTION_FAILURE,
  GET_ALL_TRANSACTION_REQUEST,
  GET_ALL_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAILURE,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_SUCCESS,
} from "./actions";
import { ShowSnackbar } from "./snackbar";

const TRANSACTION_ENDPOINT = "/api/Transaction/";

export const GetAllTransaction = (params: any) => {
  return (dispatch: any) => {
    if (localStorage.getItem("currentUser")) {
      dispatch({
        type: GET_ALL_TRANSACTION_REQUEST,
        payload: { isLoadingGetAllTransaction: true },
      });

      axios
        .get(`${TRANSACTION_ENDPOINT}GetAllTransactions`, { params })
        .then((res) => {
          dispatch({
            type: GET_ALL_TRANSACTION_SUCCESS,
            payload: {
              isLoadingGetAllTransaction: false,
              transactionList: res?.data,
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_ALL_TRANSACTION_FAILURE,
            payload: { isLoadingGetAllTransaction: false },
          });
        });
    }
  };
};

export const AddTransaction = (data: any) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: ADD_TRANSACTION_REQUEST,
        payload: { isLoadingAddTransaction: true },
      });

      axios
        .post(`${TRANSACTION_ENDPOINT}Create`, data)
        .then((res) => {
          dispatch({
            type: ADD_TRANSACTION_SUCCESS,
            payload: { isLoadingAddTransaction: false },
          });
          dispatch(ShowSnackbar("添加成功", "success"));
          resolve(true); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: ADD_TRANSACTION_FAILURE,
            payload: { isLoadingAddTransaction: false },
          });
          dispatch(ShowSnackbar("添加失败", "error"));
          resolve(false); // Return false on failure
        });
    });
  };
};

export const UpdateTransaction = (data: API.TransactionListItem) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: UPDATE_TRANSACTION_REQUEST,
        payload: { isLoadingUpdateTransaction: true },
      });

      axios
        .post(`${TRANSACTION_ENDPOINT}Update`, data)
        .then((res) => {
          dispatch({
            type: UPDATE_TRANSACTION_SUCCESS,
            payload: { isLoadingUpdateTransaction: false },
          });
          dispatch(ShowSnackbar("更新成功", "success"));
          resolve(true); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_TRANSACTION_FAILURE,
            payload: { isLoadingUpdateTransaction: false },
          });
          dispatch(ShowSnackbar("更新失败", "error"));
          resolve(false); // Return false on failure
        });
    });
  };
};
