import React from "react";
import CustomLayout from "./Layout";

function App() {
  return (
    <>
      <CustomLayout />
    </>
  );
}

export default App;
