import React, { useEffect } from "react";
import {
  ModalForm,
  ProFormText,
  ProForm,
  ProFormSwitch,
} from "@ant-design/pro-components";
import { Form, Input } from "antd";

export type FormValueType = Partial<API.BankListItem>;

export type UpdateFormProps = {
  onCancel: any;
  //   onCancel: (flag?: boolean, formVals?: FormValueType) => void;
  onSubmit: (values: FormValueType) => Promise<void>;
  updateModalVisible: boolean;
  onVisibleChange: any;
  values: Partial<API.BankListItem>;
  updateBankLoading: boolean;
};

const UpdateForm: React.FC<UpdateFormProps> = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props.values);
  }, [props.values, form]);

  return (
    <ModalForm<API.BankListItem>
      width={700}
      title="更新银行"
      visible={props.updateModalVisible}
      onVisibleChange={props.onVisibleChange}
      modalProps={{
        onCancel: props.onCancel,
      }}
      onFinish={props.onSubmit}
      form={form}
      initialValues={{ ...props.values }}
      loading={props.updateBankLoading}
    >
      <ProForm.Group>
        <ProFormText name="id" label="ID" readonly />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText name="name" label="银行名字" width="lg" required />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSwitch
          name="isDelete"
          label="注销银行"
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </ProForm.Group>
    </ModalForm>
  );
};

export default UpdateForm;
