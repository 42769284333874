export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

//User
export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE";

//Bank
export const GET_BANK_LIST_REQUEST = "GET_BANK_LIST_REQUEST";
export const GET_BANK_LIST_SUCCESS = "GET_BANK_LIST_SUCCESS";
export const GET_BANK_LIST_FAILURE = "GET_BANK_LIST_FAILURE";

export const UPDATE_BANK_REQUEST = "UPDATE_BANK_REQUEST";
export const UPDATE_BANK_SUCCESS = "UPDATE_BANK_SUCCESS";
export const UPDATE_BANK_FAILURE = "UPDATE_BANK_FAILURE";

export const ADD_BANK_REQUEST = "ADD_BANK_REQUEST";
export const ADD_BANK_SUCCESS = "ADD_BANK_SUCCESS";
export const ADD_BANK_FAILURE = "ADD_BANK_FAILURE";

//Account
export const GET_ALL_ACCOUNT_REQUEST = "GET_ALL_ACCOUNT_REQUEST";
export const GET_ALL_ACCOUNT_SUCCESS = "GET_ALL_ACCOUNT_SUCCESS";
export const GET_ALL_ACCOUNT_FAILURE = "GET_ALL_ACCOUNT_FAILURE";

export const ADD_ACCOUNT_REQUEST = "ADD_ACCOUNT_REQUEST";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";

export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";

export const GET_ACCOUNT_BY_ID_REQUEST = "GET_ACCOUNT_BY_ID_REQUEST";
export const GET_ACCOUNT_BY_ID_SUCCESS = "GET_ACCOUNT_BY_ID_SUCCESS";
export const GET_ACCOUNT_BY_ID_FAILURE = "GET_ACCOUNT_BY_ID_FAILURE";
//Transaction
export const GET_ALL_TRANSACTION_REQUEST = "GET_ALL_TRANSACTION_REQUEST";
export const GET_ALL_TRANSACTION_SUCCESS = "GET_ALL_TRANSACTION_SUCCESS";
export const GET_ALL_TRANSACTION_FAILURE = "GET_ALL_TRANSACTION_FAILURE";

export const ADD_TRANSACTION_REQUEST = "ADD_TRANSACTION_REQUEST";
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS";
export const ADD_TRANSACTION_FAILURE = "ADD_TRANSACTION_FAILURE";

export const UPDATE_TRANSACTION_REQUEST = "UPDATE_TRANSACTION_REQUEST";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";
export const UPDATE_TRANSACTION_FAILURE = "UPDATE_TRANSACTION_FAILURE";
