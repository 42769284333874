import axios from "../AxiosConfig";
import {
  ADD_ACCOUNT_FAILURE,
  ADD_ACCOUNT_REQUEST,
  ADD_ACCOUNT_SUCCESS,
  GET_ACCOUNT_BY_ID_FAILURE,
  GET_ACCOUNT_BY_ID_REQUEST,
  GET_ACCOUNT_BY_ID_SUCCESS,
  GET_ALL_ACCOUNT_FAILURE,
  GET_ALL_ACCOUNT_REQUEST,
  GET_ALL_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
} from "./actions";
import { ShowSnackbar } from "./snackbar";

const ACCOUNT_ENDPOINT = "/api/Account/";

export const GetAllAccount = (params: any) => {
  return (dispatch: any) => {
    if (localStorage.getItem("currentUser")) {
      dispatch({
        type: GET_ALL_ACCOUNT_REQUEST,
        payload: { isLoadingGetAllAccount: true },
      });

      axios
        .get(`${ACCOUNT_ENDPOINT}GetAllAccounts`, { params })
        .then((res) => {
          dispatch({
            type: GET_ALL_ACCOUNT_SUCCESS,
            payload: { isLoadingGetAllAccount: false, accountList: res?.data },
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_ALL_ACCOUNT_FAILURE,
            payload: { isLoadingGetAllAccount: false },
          });
        });
    }
  };
};

export const AddAccount = (data: any) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: ADD_ACCOUNT_REQUEST,
        payload: { isLoadingAddAccount: true },
      });

      axios
        .post(`${ACCOUNT_ENDPOINT}Create`, data)
        .then((res) => {
          dispatch({
            type: ADD_ACCOUNT_SUCCESS,
            payload: { isLoadingAddAccount: false },
          });
          dispatch(ShowSnackbar("添加成功", "success"));
          resolve(true); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: ADD_ACCOUNT_FAILURE,
            payload: { isLoadingAddAccount: false },
          });
          dispatch(ShowSnackbar("添加失败", "error"));
          resolve(false); // Return false on failure
        });
    });
  };
};

export const UpdateAccount = (data: API.AccountListItem) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: UPDATE_ACCOUNT_REQUEST,
        payload: { isLoadingUpdateAccount: true },
      });

      axios
        .post(`${ACCOUNT_ENDPOINT}Update`, data)
        .then((res) => {
          dispatch({
            type: UPDATE_ACCOUNT_SUCCESS,
            payload: { isLoadingUpdateAccount: false },
          });
          dispatch(ShowSnackbar("更新成功", "success"));
          resolve(true); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_ACCOUNT_FAILURE,
            payload: { isLoadingUpdateAccount: false },
          });
          dispatch(ShowSnackbar("更新失败", "error"));
          resolve(false); // Return false on failure
        });
    });
  };
};

export const GetAccountById = (params: any) => {
  return (dispatch: any) => {
    if (localStorage.getItem("currentUser")) {
      dispatch({
        type: GET_ACCOUNT_BY_ID_REQUEST,
        payload: { isLoadingGetAccount: true },
      });

      axios
        .get(`${ACCOUNT_ENDPOINT}GetAccountById`, { params })
        .then((res) => {
          dispatch({
            type: GET_ACCOUNT_BY_ID_SUCCESS,
            payload: { isLoadingGetAccount: false, accountData: res?.data },
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_ACCOUNT_BY_ID_FAILURE,
            payload: { isLoadingGetAccount: false },
          });
        });
    }
  };
};
