import {
  ADD_ACCOUNT_FAILURE,
  ADD_ACCOUNT_REQUEST,
  ADD_ACCOUNT_SUCCESS,
  GET_ALL_ACCOUNT_FAILURE,
  GET_ALL_ACCOUNT_REQUEST,
  GET_ALL_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  GET_ACCOUNT_BY_ID_FAILURE,
  GET_ACCOUNT_BY_ID_REQUEST,
  GET_ACCOUNT_BY_ID_SUCCESS,
} from "../actions/actions";

const INITIAL_STATE = {
  accountList: null,
  isLoadingGetAllAccount: false,
  isLoadingAddAccount: false,
  isLoadingUpdateAccount: false,
  isLoadingGetAccount: false,
  accountData: null,
};

const AccountReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_ALL_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoadingGetAllAccount: action.payload.isLoadingGetAllAccount,
      };

    case GET_ALL_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoadingGetAllAccount: action.payload.isLoadingGetAllAccount,
        accountList: action.payload.accountList,
      };
    case GET_ALL_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoadingGetAllAccount: action.payload.isLoadingGetAllAccount,
      };
    case UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoadingUpdateAccount: action.payload.isLoadingUpdateAccount,
      };
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoadingUpdateAccount: action.payload.isLoadingUpdateAccount,
      };
    case UPDATE_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoadingUpdateAccount: action.payload.isLoadingUpdateAccount,
      };
    case ADD_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoadingAddAccount: action.payload.isLoadingAddAccount,
      };
    case ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoadingAddAccount: action.payload.isLoadingAddAccount,
      };
    case ADD_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoadingAddAccount: action.payload.isLoadingAddAccount,
      };
    case GET_ACCOUNT_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingGetAccount: action.payload.isLoadingGetAccount,
      };
    case GET_ACCOUNT_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingGetAccount: action.payload.isLoadingGetAccount,
        accountData: action.payload.accountData,
      };
    case GET_ACCOUNT_BY_ID_FAILURE:
      return {
        ...state,
        isLoadingGetAccount: action.payload.isLoadingGetAccount,
      };
    default:
      return state;
  }
};

export default AccountReducer;
