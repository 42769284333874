// src/routes.js
import {
  DASHBOARD_ROUTE,
  USER_MAIN_ROUTE,
  USER_ROUTE,
  MERCHANT_ROUTE,
  MERCHANT_ADMIN_ROUTE,
  LOGIN_ROUTE,
  BANK_ROUTE,
  ACCOUNT_ROUTE,
} from "./constant";
import {
  UserOutlined,
  DashboardOutlined,
  BankOutlined,
} from "@ant-design/icons";
const CustomRoutePath = [
  // {
  //   path: DASHBOARD_ROUTE,
  //   name: "主页",
  //   icon: <DashboardOutlined />,
  // },
  {
    path: USER_MAIN_ROUTE,
    name: "用户管理",
    icon: <UserOutlined />,
    children: [
      {
        path: USER_ROUTE,
        name: "所有用户",
      },
    ],
  },
  {
    path: ACCOUNT_ROUTE,
    name: "账户管理",
    icon: <BankOutlined />,
  },
  {
    path: BANK_ROUTE,
    name: "银行管理",
    icon: <BankOutlined />,
  },
  // Add more routes as needed
];

export default CustomRoutePath;
