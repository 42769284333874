import React, { useEffect } from "react";
import "./App.css";
import { Layout, Flex } from "antd";
import { useState } from "react";
import { ProLayout } from "@ant-design/pro-components";
import { Link, useLocation, Navigate } from "react-router-dom";
import CustomRoutePath from "./CustomRoutePath";
import Logo from "./images/logo.png";
import {
  USER_ROUTE,
  MERCHANT_ROUTE,
  MERCHANT_ADMIN_ROUTE,
  DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  BANK_ROUTE,
  ACCOUNT_ROUTE,
  TRANSACTION_ROUTE,
} from "./constant";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import UserListPage from "./pages/user/all-user";
import MerchantListPage from "./pages/user/merchant";
import MerchantAdminListPage from "./pages/user/merchant-admin";
import DashboardPage from "./pages/dashboard";
import LoginPage from "./pages/login";
import BankPage from "./pages/bank";
import AccountPage from "./pages/account";
import TransactionPage from "./pages/transaction";
import { MenuFoldOutlined } from "@ant-design/icons";
import ProtectedRoute from "./ProtectedRoute";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { GetUserInfo } from "./actions/user";
import AvatarDropdown from "./components/AvatarDropdown";
const { Header, Content } = Layout;

const CustomLayout = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const pathname = location.pathname;
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const menuDataRender = () =>
    CustomRoutePath.map((item) => ({
      ...item,
      icon: item.icon,
      children: item.children
        ? item.children.map((child) => ({
            ...child,
          }))
        : undefined,
    }));

  const excludeLayout = pathname === LOGIN_ROUTE ? true : false;

  useEffect(() => {
    const excludeLayout = pathname === LOGIN_ROUTE ? true : false;
    if (!excludeLayout) {
      dispatch(GetUserInfo());
    }
  }, [pathname, dispatch]);

  return (
    <>
      {excludeLayout ? (
        <>
          <Routes>
            <Route path={LOGIN_ROUTE} element={<LoginPage />} />
          </Routes>
        </>
      ) : (
        <>
          <ProLayout
            layout="mix"
            logo={<img src={Logo} alt="logo" height="100%" width="auto" />}
            collapsed={collapsed}
            onCollapse={toggleCollapse}
            menuDataRender={menuDataRender}
            headerContentRender={() => (
              <>
                <Flex justify="end">
                  <AvatarDropdown />
                </Flex>
              </>
            )}
            collapsedButtonRender={() => (
              <div
                style={{
                  padding: "16px",
                  backgroundColor: "#ffffff",
                  borderTop: "2px solid #ededed",
                  cursor: "pointer",
                }}
                onClick={toggleCollapse}
              >
                <MenuFoldOutlined style={{ fontSize: "16px" }} />
              </div>
            )}
            navTheme="light"
            contentWidth="Fluid"
            fixedHeader={false}
            fixSiderbar={true}
            menuProps={{
              style: {
                backgroundColor: "#ffffff", // Custom background color
                paddingTop: "16px",
              },
            }}
            menuItemRender={(item, dom) => (
              <Link to={item.path ? item.path : "#"}>
                <span
                  style={{
                    color: item.path === pathname ? "#710983" : "inherit",
                  }}
                >
                  {dom}
                </span>
              </Link>
            )}
          >
            <Content style={{ padding: "10px" }}>
              {/* <CustomRoute /> */}
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route
                    path="/"
                    element={<Navigate to={USER_ROUTE} replace />}
                  />
                  <Route path="/" element={<UserListPage />} />
                  {/* <Route path={DASHBOARD_ROUTE} element={<DashboardPage />} /> */}
                  <Route path={USER_ROUTE} element={<UserListPage />} />
                  <Route path={BANK_ROUTE} element={<BankPage />} />
                  <Route path={ACCOUNT_ROUTE} element={<AccountPage />} />
                  <Route
                    path={TRANSACTION_ROUTE}
                    element={<TransactionPage />}
                  />
                  {/* <Route path={MERCHANT_ROUTE} element={<MerchantListPage />} />
                  <Route
                    path={MERCHANT_ADMIN_ROUTE}
                    element={<MerchantAdminListPage />}
                  /> */}
                </Route>
              </Routes>
            </Content>
          </ProLayout>
        </>
      )}
    </>
  );
};

export default CustomLayout;
