import {
  PageContainer,
  ProTable,
  ProFormText,
  ModalForm,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-components";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Button, Form } from "antd";
import moment from "moment";
import UpdateForm from "./components/UpdateForm";
import { PlusOutlined } from "@ant-design/icons";
import {
  AddAccount,
  GetAllAccount,
  UpdateAccount,
} from "../../actions/account";
import { GetAllUsers } from "../../actions/user";
import { useNavigate } from "react-router-dom";
const Account = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const account_selector = useAppSelector((state) => state.account);
  const user_selector = useAppSelector((state) => state.user);
  const actionRef = useRef<ActionType>();
  const [createForm] = Form.useForm();
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);

  const [currentRow, setCurrentRow] = useState<API.AccountListItem>();

  useEffect(() => {
    dispatch(GetAllAccount({}));
    dispatch(GetAllUsers({ pageNumber: 1, pageSize: 1000 }));
  }, [dispatch]);

  const handleSearch = (params: any) => {
    dispatch(GetAllAccount(params));
  };

  const handleReset = () => {
    dispatch(GetAllAccount({}));
  };

  const handleAddAccount = async (values: any) => {
    const status = await dispatch(AddAccount(values));
    if (status) {
      createForm.resetFields();
      handleModalVisible(false);
      dispatch(GetAllAccount({}));
    }
  };
  const handleUpdateAccount = async (data: API.AccountListItem) => {
    const status = await dispatch(UpdateAccount(data));
    return status;
  };
  const columns: ProColumns<API.AccountListItem>[] = [
    {
      title: "ID", // Column header
      dataIndex: "id", // Key in the dataSource object
      key: "id", // Unique key for this column
      hideInSearch: true,
    },
    {
      title: "登入用户名",
      dataIndex: "userName",
    },
    {
      title: "组别",
      dataIndex: "category",
      hideInSearch: true,
    },
    {
      title: "户口类型",
      dataIndex: "type",
      hideInSearch: true,
    },
    {
      title: "户口号码",
      dataIndex: "accountNumber",
    },
    {
      title: "当前户口数额",
      dataIndex: "availableAmount",
      hideInSearch: true,
    },
    {
      title: "当前可转账数额",
      dataIndex: "withdrawableAmount",
      hideInSearch: true,
    },
    {
      title: "剩余转账数额",
      dataIndex: "transferLimit",
      hideInSearch: true,
    },
    {
      title: "输入日期",
      dataIndex: "createdAt",
      valueType: "date",
      render: (dom, entity) => {
        const createdDate = moment(entity.createdAt).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        return createdDate;
      },
    },

    {
      title: "状态",
      dataIndex: "isDelete",
      valueEnum: {
        false: {
          text: "活跃",
          status: "Success",
        },
        true: {
          text: "注销",
          status: "Error",
        },
      },
    },
    {
      title: "选项",
      dataIndex: "option",
      valueType: "option",
      hideInDescriptions: true,
      render: (_, record) => [
        <Button
          color="primary"
          variant="link"
          onClick={() => navigate(`/account/${record?.id}/transaction`)}
        >
          查询流水单
        </Button>,
        <Button
          color="primary"
          variant="link"
          onClick={() => {
            handleUpdateModalVisible(true);
            setCurrentRow(record);
          }}
        >
          更改
        </Button>,
      ],
    },
  ];

  return (
    <>
      <PageContainer>
        <div>
          <ProTable<API.AccountListItem, API.PageParams>
            actionRef={actionRef}
            headerTitle="账号列表"
            columns={columns}
            rowKey="id"
            pagination={{
              pageSize: 10,
              // current: bank_selector?.bankList?.pageNumber,
            }}
            dataSource={account_selector?.accountList?.data}
            loading={account_selector?.isLoadingGetAllAccount}
            onSubmit={handleSearch}
            onReset={handleReset}
            toolBarRender={() => [
              <Button
                type="primary"
                key="primary"
                onClick={() => {
                  handleModalVisible(true);
                }}
              >
                <PlusOutlined />
                添加账户
              </Button>,
            ]}
          />
          <ModalForm
            modalProps={{
              maskClosable: false,
            }}
            loading={account_selector?.isLoadingAddAccount}
            title="添加账户"
            visible={createModalVisible}
            onVisibleChange={handleModalVisible}
            width="400px"
            form={createForm}
            onFinish={async (value) => {
              await handleAddAccount(value);
            }}
          >
            <>
              <ProFormSelect
                rules={[
                  {
                    required: true,
                    message: "请选择用户",
                  },
                ]}
                width="md"
                name="userId"
                label="用户"
                options={user_selector?.userList?.data
                  ?.filter((item: any) => item.userName !== "Admin001") // Filter out unwanted items first
                  .map((item: any) => ({
                    label: item.userName, // Use userName as label
                    value: item.id, // Use id as value
                  }))}
              />
              <ProFormText
                rules={[
                  {
                    required: true,
                    message: "请填入组别",
                  },
                ]}
                width="md"
                name="category"
                label="组别"
              />
              <ProFormText
                rules={[
                  {
                    required: true,
                    message: "请填入户口类型",
                  },
                ]}
                width="md"
                name="type"
                label="户口类型"
              />
              <ProFormText
                rules={[
                  {
                    required: true,
                    message: "请填入户口号码",
                  },
                ]}
                width="md"
                name="accountNumber"
                label="户口号码"
              />
              <ProFormDigit
                rules={[
                  {
                    required: true,
                    message: "请输入当前户口数额",
                  },
                ]}
                width="md"
                name="availableAmount"
                label="当前户口数额 (Available Amount)"
              />
              <ProFormDigit
                rules={[
                  {
                    required: true,
                    message: "请输入当前可转账数额",
                  },
                ]}
                width="md"
                name="withdrawableAmount"
                label="当前可转账数额 (Withdrawable Amount)"
              />
              <ProFormDigit
                rules={[
                  {
                    required: true,
                    message: "请输入剩余转账数额",
                  },
                ]}
                width="md"
                name="transferLimit"
                label="剩余转账数额 (Transfer Limit Left)"
              />
            </>
          </ModalForm>
          <UpdateForm
            onSubmit={async (value) => {
              const success = await handleUpdateAccount(value);
              if (success) {
                handleUpdateModalVisible(false);
                setCurrentRow(undefined);
                dispatch(GetAllAccount({}));
              }
            }}
            updateAccountLoading={account_selector?.isLoadingUpdateAccount}
            onCancel={() => {
              handleUpdateModalVisible(false);
              setCurrentRow(undefined);
            }}
            onVisibleChange={handleUpdateModalVisible}
            updateModalVisible={updateModalVisible}
            values={currentRow || {}}
          />
        </div>
      </PageContainer>
    </>
  );
};

export default Account;
