import React, { useEffect } from "react";
import {
  ModalForm,
  ProFormText,
  ProForm,
  ProFormSwitch,
} from "@ant-design/pro-components";
import { Form, Input } from "antd";

export type FormValueType = Partial<API.UserListItem>;

export type UpdateFormProps = {
  onCancel: any;
  //   onCancel: (flag?: boolean, formVals?: FormValueType) => void;
  onSubmit: (values: FormValueType) => Promise<void>;
  updateModalVisible: boolean;
  onVisibleChange: any;
  values: Partial<API.UserListItem>;
  updateUserLoading: boolean;
};

const UpdateForm: React.FC<UpdateFormProps> = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props.values);
  }, [props.values, form]);

  return (
    <ModalForm<API.UserListItem>
      width={700}
      title="更新用户"
      visible={props.updateModalVisible}
      onVisibleChange={props.onVisibleChange}
      modalProps={{
        onCancel: props.onCancel,
      }}
      onFinish={props.onSubmit}
      form={form}
      initialValues={{ ...props.values }}
      loading={props.updateUserLoading}
    >
      <ProForm.Group>
        <ProFormText name="id" label="用户ID" readonly />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText name="name" label="用户全名" />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText name="userName" label="登入用户名" />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSwitch
          name="isCurrentAcc"
          label="为当前设定用户"
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </ProForm.Group>
    </ModalForm>
  );
};

export default UpdateForm;
