import React, { useEffect } from "react";
import {
  ModalForm,
  ProFormText,
  ProForm,
  ProFormSwitch,
  ProFormDigit,
  ProFormTextArea,
  ProFormSelect,
} from "@ant-design/pro-components";
import { Form, Input } from "antd";

export type FormValueType = Partial<API.TransactionListItem>;

export type UpdateFormProps = {
  onCancel: any;
  //   onCancel: (flag?: boolean, formVals?: FormValueType) => void;
  onSubmit: (values: FormValueType) => Promise<void>;
  updateModalVisible: boolean;
  onVisibleChange: any;
  values: Partial<API.TransactionListItem>;
  updateTransactionLoading: boolean;
};

const UpdateForm: React.FC<UpdateFormProps> = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props.values);
  }, [props.values, form]);

  return (
    <ModalForm<API.TransactionListItem>
      width={700}
      title="更新账户"
      visible={props.updateModalVisible}
      onVisibleChange={props.onVisibleChange}
      modalProps={{
        onCancel: props.onCancel,
      }}
      onFinish={props.onSubmit}
      form={form}
      initialValues={{ ...props.values }}
      loading={props.updateTransactionLoading}
    >
      <ProForm.Group>
        <ProFormText name="id" label="系统ID" readonly />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSelect
          rules={[
            {
              required: true,
              message: "请选择In/Out",
            },
          ]}
          width="md"
          name="type"
          label="In/Out"
          options={[
            { label: "In", value: 1 },
            { label: "Out", value: 2 },
          ]}
        />
        <ProFormTextArea
          name="description"
          label="流水简介"
          width="md"
          rules={[
            {
              required: true,
              message: "请输入流水简介",
            },
          ]}
        />
        <ProFormDigit
          rules={[
            {
              required: true,
              message: "请输入流水数额",
            },
          ]}
          width="md"
          name="amount"
          label="数额 "
        />
        <ProFormSwitch
          name="isDelete"
          label="注销此流水"
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </ProForm.Group>
    </ModalForm>
  );
};

export default UpdateForm;
