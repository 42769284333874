import {
  ADD_USER_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_USER_INFO_FAILURE,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  POST_LOGIN_FAILURE,
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../actions/actions";

const INITIAL_STATE = {
  isLoadingGetUserInfo: false,
  userInfo: null,
  isLoadingLogin: false,
  userList: null,
  isLoadingGetAllUsers: false,
  isLoadingAddUser: false,
  isLoadingUpdateUser: false,
};

const UserReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        isLoadingGetUserInfo: action.payload.isLoadingGetUserInfo,
      };

    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoadingGetUserInfo: action.payload.isLoadingGetUserInfo,
        userInfo: action.payload.userInfo,
      };
    case GET_USER_INFO_FAILURE:
      return {
        ...state,
        isLoadingGetUserInfo: action.payload.isLoadingGetUserInfo,
      };
    case POST_LOGIN_REQUEST:
      return {
        ...state,
        isLoadingLogin: action.payload.isLoadingLogin,
      };
    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        isLoadingLogin: action.payload.isLoadingLogin,
      };
    case POST_LOGIN_FAILURE:
      return {
        ...state,
        isLoadingLogin: action.payload.isLoadingLogin,
      };
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
        isLoadingGetAllUsers: action.payload.isLoadingGetAllUsers,
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        isLoadingGetAllUsers: action.payload.isLoadingGetAllUsers,
        userList: action.payload.userList,
      };
    case GET_ALL_USERS_FAILURE:
      return {
        ...state,
        isLoadingGetAllUsers: action.payload.isLoadingGetAllUsers,
      };
    case ADD_USER_REQUEST:
      return {
        ...state,
        isLoadingAddUser: action.payload.isLoadingAddUser,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        isLoadingAddUser: action.payload.isLoadingAddUser,
      };
    case ADD_USER_FAILURE:
      return {
        ...state,
        isLoadingAddUser: action.payload.isLoadingAddUser,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isLoadingUpdateUser: action.payload.isLoadingUpdateUser,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoadingUpdateUser: action.payload.isLoadingUpdateUser,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        isLoadingUpdateUser: action.payload.isLoadingUpdateUser,
      };
    default:
      return state;
  }
};

export default UserReducer;
