import {
  PageContainer,
  ProTable,
  ModalForm,
  ProFormText,
} from "@ant-design/pro-components";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { useEffect, useRef, useState } from "react";
import { Button, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { AddUser, GetAllUsers, UpdateUser } from "../../../actions/user";
import moment from "moment";
import UpdateForm from "./components/UpdateForm";
const AllUsers = () => {
  const [createForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const user_selector = useAppSelector((state) => state.user);
  const actionRef = useRef<ActionType>();
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<API.UserListItem>();
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  useEffect(() => {
    dispatch(GetAllUsers({}));
  }, [dispatch]);

  const handleSearch = (params: any) => {
    dispatch(GetAllUsers(params));
  };

  const handleReset = () => {
    dispatch(GetAllUsers({}));
  };

  const handleAddUser = async (data: any) => {
    const status = await dispatch(AddUser(data));
    if (status) {
      createForm.resetFields();
      handleModalVisible(false);
      dispatch(GetAllUsers({}));
    }
  };

  const handleUpdateUser = async (data: API.UserListItem) => {
    const status = await dispatch(UpdateUser(data));
    return status;
  };

  const columns: ProColumns<API.UserListItem>[] = [
    {
      title: "ID", // Column header
      dataIndex: "id", // Key in the dataSource object
      key: "id", // Unique key for this column
      hideInSearch: true,
    },
    {
      title: "用户全名",
      dataIndex: "name",
      hideInSearch: true,
    },
    {
      title: "邮箱",
      dataIndex: "email",
      hideInSearch: true,
    },
    {
      title: "登入用户名",
      dataIndex: "userName",
    },
    {
      title: "创建日期",
      dataIndex: "createdAt",
      valueType: "date",
      render: (dom, entity) => {
        const createdDate = moment(entity.createdAt).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        return createdDate;
      },
    },

    {
      title: "为当前设定用户",
      dataIndex: "isCurrentAcc",
      valueEnum: {
        false: {
          text: "否",
          status: "Error",
        },
        true: {
          text: "是",
          status: "Success",
        },
      },
    },
    {
      title: "选项",
      dataIndex: "option",
      valueType: "option",
      hideInDescriptions: true,
      render: (_, record) => {
        if (record.userName !== "Admin001") {
          return (
            <Button
              color="primary"
              variant="link"
              onClick={() => {
                handleUpdateModalVisible(true);
                setCurrentRow(record);
              }}
            >
              更改
            </Button>
          );
        }
      },
    },
  ];
  return (
    <>
      <PageContainer>
        <div>
          <ProTable<API.UserListItem, API.PageParams>
            actionRef={actionRef}
            headerTitle="银行列表"
            columns={columns}
            rowKey="id"
            pagination={{
              pageSize: 10,
              current: user_selector?.userList?.pageNumber,
            }}
            dataSource={user_selector?.userList?.data}
            loading={user_selector?.isLoadingGetAllUsers}
            onSubmit={handleSearch}
            onReset={handleReset}
            search={{ labelWidth: "auto" }}
            toolBarRender={() => [
              <Button
                type="primary"
                key="primary"
                onClick={() => {
                  handleModalVisible(true);
                }}
              >
                <PlusOutlined />
                添加用户
              </Button>,
            ]}
          />
          <ModalForm
            modalProps={{
              maskClosable: false,
            }}
            loading={user_selector?.isLoadingAddUser}
            title="添加用户"
            visible={createModalVisible}
            onVisibleChange={handleModalVisible}
            width="400px"
            form={createForm}
            onFinish={async (value) => {
              await handleAddUser(value);
            }}
          >
            <>
              <ProFormText hidden name="role" initialValue="User" />
              <ProFormText
                rules={[
                  {
                    required: true,
                    message: "请输入全名",
                  },
                ]}
                width="md"
                name="name"
                label="用户全名"
              />
              <ProFormText
                rules={[
                  {
                    required: true,
                    message: "请输入登入用户名",
                  },
                ]}
                width="md"
                name="userName"
                label="登入用户名"
              />
              <ProFormText.Password
                width="md"
                name="password"
                label="密码"
                rules={[
                  {
                    required: true,
                    message: "请输入密码",
                  },
                ]}
              />
            </>
          </ModalForm>
          <UpdateForm
            onSubmit={async (value) => {
              const success = await handleUpdateUser(value);
              if (success) {
                handleUpdateModalVisible(false);
                setCurrentRow(undefined);
                dispatch(GetAllUsers({}));
              }
            }}
            updateUserLoading={user_selector?.isLoadingUpdateUser}
            onCancel={() => {
              handleUpdateModalVisible(false);
              setCurrentRow(undefined);
            }}
            onVisibleChange={handleUpdateModalVisible}
            updateModalVisible={updateModalVisible}
            values={currentRow || {}}
          />
        </div>
      </PageContainer>
    </>
  );
};

export default AllUsers;
