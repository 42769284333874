import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import UserReducer from "../reducers/user";
import SnackBarReducer from "../reducers/snackbar";
import BankReducer from "../reducers/bank";
import AccountReducer from "../reducers/account";
import TransactionReducer from "../reducers/transaction";
export const store = configureStore({
  reducer: {
    snackbar: SnackBarReducer,
    user: UserReducer,
    bank: BankReducer,
    account: AccountReducer,
    transaction: TransactionReducer,
    // gateway: GatewayReducer,
    // paymode: PayModeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
