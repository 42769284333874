import axios from "axios";
import { notification } from "antd";

const codeMessage: any = {
  200: "The server successfully returned the requested data",
  201: "New or modified data successfully",
  202: "A request has entered the backgroud queue (asynchronous task)",
  204: "Data deleted successfully",
  400: "There was an error in the request sent, and the server did not create or modify data",
  401: "The user does not have permission (token,username,password is wrong)",
  403: "The user is authorized, but access is forbidden",
  404: "The request sent is for a record that does not exist, and the server is not operating",
  406: "The requested format is not available",
  410: "The requested resource is permanently deleted and will no longer be available",
  422: "When creating an object,a validation error occured",
  500: "An error occured on the server, please check the server",
  502: "Gateway error",
  503: "The service is unavailable, the server is temporarily overloaded or maintained",
  504: "Gateway timeout",
};

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.REACT_APP_API_URL,
  //baseURL: "https://localhost:44338",
});

instance.interceptors.request.use((config) => {
  const user = localStorage.getItem("currentUser")!;
  const loginInfo = JSON.parse(user);
  // const token = localStorage.getItem("token");
  config.headers["Authorization"] = loginInfo?.accessToken
    ? `Bearer ${loginInfo?.accessToken}`
    : "";
  return config;
});

instance.interceptors.response.use(
  (res) => {
    // Add configurations here
    return res;
  },
  (err) => {
    console.log(err);
    const errorCode = err?.response?.status;
    if (err?.response?.status === 401) {
      notification.error({
        message: `Error Request ${errorCode}`,
        description: "登入失效 请重新登入",
      });
      localStorage.clear();
      window.location.href = "/login";
    } else {
      console.log(err?.response);
      const errorText = err?.response?.data?.message
        ? err?.response?.data?.message
        : codeMessage[errorCode];
      // notification.error({
      //   message: `Request error ${errorCode}`,
      //   description: errorText,
      // });
    }
    return Promise.reject(err);
  }
);

// instance.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         return new Promise((resolve,reject) => {
//             if(error.response === undefined || error.response.status !== 401){
//                 return reject(error.response);
//             }else{
//                 axios.interceptors.response.eject(instance);
//                 return reject(error);
//             }
//         });
//     }
// );

export default instance;
